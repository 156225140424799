import './vendor/01_modernizr.min.js'
import Headroom from './vendor/02_headroom.min.js'
import './vendor/03_lazysizes.min.js'
import './vendor/04_magnific-popup.min.js'
import './vendor/05_cssua.min.js'
import './vendor/06_owl.carousel.min.js'
import './vendor/07_eve.js'
import './vendor/09_mapael.js'
import Waypoint  from './vendor/10_jquery.waypoints.min.js'
import './data-maps/world-countries.js'

(function($, window, document, undefined) {
  // 'use strict';

  /**
   * This is a description for Mangrove object.
   *
   * @namespace Mangrove
   * @author Mangrove
   */

  var Mangrove = {

    /***
     * Initialize object
     * @function init
     * @memberof Mangrove
     */

    init: function() {

      this._cache();
      this._events();
      this._initHeadroom();
      this._initPopups();
      this._initOwl();
      this._initMobileButton();
      this._initVideoSlowdown();
      this._initSmoothScroll();
      this._createMapPlots(window['mapLocations']);
      this._initProgramsFns();
      this._eventDescExpand();

    }, // init()

    /**
     * Cache reusable data
     * @function cache
     * @memberof Mangrove
     */

    _cache: function() {

      this.$win = $(window);
      this.$html = $('html');
      this.$body = $('body');
      this.$mainWrap = $('main');
      this.$header = $('.js-header');
      this.$mobileNav = $('.js-mobile-nav');
      this.$hamburger = $('.js-hamburger');
      this.$sideNav = $('.js-nav-side');
      this.$sideHamburger = $('.js-slide-menu');

    }, // _cache()

    /**
     * Attach event listeners
     * @function _events
     * @memberof Mangrove
     */

    _events: function() {
      this.$html.on(
          'click',
          '.js-hamburger',
          this._showMobileNav.bind(this)
      );

      this.$html.on(
          'click',
          '.js-slide-menu',
          this._showSideNav.bind(this)
      );

      this.$html.on(
          'click',
          '.js-close-slide',
          this._showSideNav.bind(this)
      );

      $(window).load(function() {
        setTimeout(function(){
          $('.newsletter-cta').addClass('ready');
        }, 100);
      });

      $('.js-down').click(function() {
        var offset = 0;
        $('html, body').animate({
          scrollTop: $(".bb-hero").next().offset().top + offset
        }, 500);
        return false;
      });

      $('.on-page-nav').each(function(i) {
        var numItems = $('.on-page-nav').length;
        $(this).addClass('navsection--'+(i+1)+'of'+numItems);
        i++;
      });

      $('.sub-menu').on('click', 'li', function() {
        $('.sub-menu li.active').removeClass('active');
        $(this).addClass('active');
      });

    }, // _events()

    _utilSlugify: function(str) {
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();

      var from = "àáäâèéëêìíïîòóöôùúüûñç·/,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";
      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '')
          .replace(/\s+/g, '_')
          .replace(/-+/g, '_');

      return str;
    },
    _utilObjectLength( object ) {
      var length = 0;
      for( var key in object ) {
          if( object.hasOwnProperty(key) ) {
              ++length;
          }
      }
      return length;
    },
    _initPopups: function() {
      $('.js-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 99,
        mainClass: 'popup--animate'
      });
      $('.js-video').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 99,
        mainClass: 'popup--animate',
        callbacks: {
          open: function() {
            $('html').css('margin-right', 0);
            $(this.content).find('video')[0].play();
          },
          close: function() {
            $(this.content).find('video')[0].load();
          }
        }
      });
      function setCookie(key, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + 345600000); // 1 month
        document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
      }
      function getCookie(key) {
          var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
          return keyValue ? keyValue[2] : null;
      }
      $(window).load(function () {
          var hasVisited = getCookie('pop-closed');
          if (hasVisited == null) {
            var waypoints = $('.home .has-popup .bb-slideshow.border-curved--none').waypoint(function(direction) {
              $.magnificPopup.open({
                items: {
                    src: '#home-pop'
                },
                type: 'inline',
                enableEscapeKey: false,
                closeOnBgClick: false,
                mainClass: 'popup-home--animate'
              }, 0);
              this.destroy()
            },{
              offset: '41%'
            });
          }
      });
      $(document).on("click", ".mfp-close", function (e) {
          setCookie('pop-closed', true);
      });
    },

    _initVideoSlowdown: function() {
      var elem = document.querySelector('.home #bgvid');
      if (elem) {
        elem.addEventListener('timeupdate', function() {
          if (elem.currentTime >= 10) {
            elem.playbackRate = 0.75;
          }
        }, false);
      }
    },

    _eventDescExpand() {
      var animspeed = 400;
      var table = $('.js-expanding-desc');
      var height = table.height();
      var mini = $('.js-expanding-desc p').eq(0).height() + $('.js-expanding-desc p').eq(1).height() + $('.js-expanding-desc p').eq(2).height() + $('.js-expanding-desc p').eq(3).height() + 30;
      table.attr('data-fullheight',height+'px');
      table.attr('data-miniheight',mini+'px');
      table.css('height',mini+'px');
      $('.js-desc-expand').on('click', function(e){
        var rows = $(this).prev();
        rows.animate({
          'height': rows.attr('data-fullheight')
        }, animspeed);
        $(this).next('.js-desc-contract').removeClass('hide');
        $(this).addClass('hide');
      });
      $('.js-desc-contract').on('click', function(e){
        var rows = $(this).prev().prev();
        rows.animate({
          'height': rows.attr('data-miniheight')
        }, animspeed);
        $(this).prev('.js-desc-expand').removeClass('hide');
        $(this).addClass('hide');
      });
    },

    _initHeadroom: function() {
      var theHeader = document.querySelector('.js-header');
      var headroom = new Headroom(theHeader);
      headroom.init();
    },

    _initOwl: function() {
      $(document).ready(function(){
        $(".owl-carousel").owlCarousel({
          items: 1,
          loop: true,
          nav: true,
          dots: true,
          autoHeight: true
        });
      });
    },

    _initMobileButton: function() {
      if (window.matchMedia("(max-width: 699px)").matches) {
        $( ".home .bb-slideshow.border-curved--none" ).append( "<a class='button button--secondary button--appended' href='http://localhost:8080/testimonials/'>More Success Stories</a>" );
      }
    },

    _initSmoothScroll: function() {
      $('a[href*="#"]')
      .not('[href="#"]')
      .not('[href="#0"]')
      .not('[href="#popup"]')
      .not('[href="#popup-0"]')
      .not('[href="#popup-1"]')
      .not('[href="#popup-2"]')
      .not('[href="#video"]')
      .click(function(event) {
        if (
          location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
          &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top - 80
            }, 1000
            );
          }
        }
      });
    },
    _initProgramsFns() {
      if ( window.mapLocations && window.mapLocations.length <= 1 ) {
        $('.grid-view-container').addClass('show');
        $('.map-view-container').removeClass('show');
      }
      $(document).on('click', function() {
        $('.map .is-shown.program_card--outer').removeClass('is-shown');
      })
      $(document).on('click', '.view-switch > i', function(e) {

        var role = $(this).attr('role');

        $('.switch--body').each(function() {
          $(this).removeClass('show');
        })
        $(`.${role}-container`).addClass('show');
      });
    },
    _createMapPlots: function(locations) {
      function locationObject(lat, lng) {
          this.latitude = lat;
          this.longitude = lng;
          this.path = "M10.632,12.305c0,2.273,1.586,4.123,3.535,4.123s3.535-1.85,3.535-4.123c0-0.908-0.248-1.77-0.718-2.49c-0.232-0.358-0.172-0.867,0.134-1.14c0.307-0.271,0.743-0.201,0.978,0.156c0.654,1.007,1,2.207,1,3.474c0,3.171-2.211,5.75-4.929,5.75c-2.717,0-4.929-2.579-4.929-5.75c0-3.17,2.211-5.75,4.929-5.75c0.385,0,0.696,0.365,0.696,0.813c0,0.449-0.312,0.813-0.696,0.813C12.218,8.182,10.632,10.031,10.632,12.305z M14.807,255.393L14.661,36.409c0.016-0.018,0.039-0.025,0.054-0.045C15.16,35.769,25.62,21.66,25.62,11.863C25.62,5.322,20.473,0,14.143,0C7.814,0,2.667,5.322,2.667,11.863c0,5.851,3.925,13.519,7.219,18.921c0.212,0.349,0.658,0.453,0.995,0.233c0.337-0.219,0.438-0.68,0.226-1.028C5.324,20.502,4.11,14.896,4.11,11.863c0-5.719,4.501-10.371,10.033-10.371c5.533,0,10.034,4.652,10.034,10.371c0,9.288-10.498,23.447-10.604,23.59c-0.219,0.292-0.182,0.693,0.054,0.956l0.145,219.032c-2.006,0.357-3.531,2.104-3.531,4.212c0,2.368,1.919,4.288,4.288,4.288c2.368,0,4.288-1.92,4.288-4.288C18.816,257.378,17.042,255.539,14.807,255.393z";
      }
      if (locations) {
        locations = locations.filter((a) => a.address)

        if (locations.length > 0) {
          this._generatePlots(locations, locationObject);
        }
      }
    },
    _generatePlots: function(locations, object) {
      var _this = this;
      var mapPins = {};

      $.each(locations, function(key, address){
          var geocoder = new google.maps.Geocoder();
          var address_name = _this._utilSlugify(address.address);

          geocoder.geocode( { 'address': address.address}, function(results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              mapPins[address_name] = new object(results[0].geometry.location.lat(), results[0].geometry.location.lng())
              mapPins[address_name].location = address.address;
              mapPins[address_name].date = address.date;
              mapPins[address_name].link = address.link;
              mapPins[address_name].title = address.title;
              mapPins[address_name].excerpt = address.excerpt;
              //mapPins[address_name].attrsHover = createHoverBox;
            }
          });
      });
      var createHoverBox = function(event) {
        return alert(event.title)
      }
      
      const startCheck = new Date().getTime();
      var check = setInterval(function(){
        if(  _this._utilObjectLength(mapPins) == (locations.length) || new Date().getTime() - startCheck > 15000 ) {
            _this._initProgramsMap(mapPins);
            clearInterval(check);
        }
      }, 300);
    },
    _initProgramsMap: function(plots) {
      $("#programs-map").mapael({
          map: {
            name: "world_countries",
            defaultArea: {
                attrs: {
                    fill: "#466B7B",
                    stroke: "#466B7B",
                },
                attrsHover: {
                    fill: "#466B7B"
                },
            },
             defaultPlot: {
                type: "svg",
                width: 23,
                height: 257,
                attrs: {
                  cursor: "pointer",
                  fill: "#CCA15B",
                  transform: 't0,-125'
                },
                attrsHover: function() {
                  return false
                },
                eventHandlers: {
                  mouseover: function (e, id, mapElem, textElem, elemOptions) {
                  var programTemplate =
                    `<div id=${id} class="is-shown program_card--outer grid__column one-of-three">
                      <div class="program_card--inner u-text-center u-m2 grid__item">
                          <p class="program--date">
                          ${elemOptions.date}
                          </p>
                        <div class="program--location--conatiner">
                          <p class="program--location">${elemOptions.location}</p>
                        </div>
                        <div class="program_card--info u-mt3">
                          <h4>
                            ${elemOptions.title}
                          </h4>
                          <div class="program--description">
                          ${elemOptions.excerpt}
                          </div>
                        </div>
                        <a class="details--link button button--default u-mt4" href="${elemOptions.link}">
                          Details
                        </a>
                      </div>
                    </div>`;
                    //$(e.currentTarget).css({'fill-opacity': 0}).fadeOut('slow');
                    var wrapper = $(e.currentTarget).parent();
                    var parentOffset = wrapper.offset();
                    var relX = e.pageX - parentOffset.left + wrapper.scrollLeft();
                    var relY = e.pageY - parentOffset.top + wrapper.scrollTop();
                    if (!$('.is-shown.program_card--outer').length) {
                      $('.map').append($(programTemplate).css({
                          left: relX,
                          top: relY,
                          position: 'absolute'
                      }));
                    } else {
                      $('.map .program_card--outer').each(function() {
                        $(this).removeClass('is-shown');
                        $(this).remove();
                        $('.map').append($(programTemplate).css({
                            left: relX,
                            top: relY,
                            position: 'absolute'
                        }));
                      })
                    }
                  },
                  mouseout: function (e, id) {
                    return false
                    //setTimeout(function() {
                      //$(e.currentTarget).css({'fill-opacity': 1}).fadeIn('slow')
                    //}, 350);

                  }
                }
             }
          },
          plots: plots
      });
    },
    _showMobileNav: function(e) {
      e.preventDefault();
      this.$mobileNav.toggleClass('is-active');
      this.$hamburger.toggleClass('is-active');
    },
    _showSideNav: function(e) {
      e.preventDefault();
      this.$sideNav.toggleClass('active');
      this.$sideHamburger.toggleClass('active');
    },

  }; // Mangrove

  Mangrove.init();

})(jQuery, window, document);
